const applyStyle = () => {
  const element = document.querySelector('[id^="minimized-trustbadge-"]');
  if (element) {
      element.style.setProperty("left", "20px", "important");
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const observer = new MutationObserver(() => {
      applyStyle();
  });

  observer.observe(document.body, { childList: true, subtree: true });

  applyStyle();
});

window.addEventListener("resize", applyStyle);