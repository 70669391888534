import $ from "jquery/src/jquery"

require('../../node_modules/uikit/dist/js/uikit.min.js');
require('../../assets/scripts/function.js');
require('../../assets/scripts/retina.min.js');
require('./price_scrolling.js');
require('./e-trusted-shops.js');

require('../stylesheets/site.css.scss');

window.zenscroll = require('zenscroll');
